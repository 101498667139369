import {
  AwardsSection,
  DifferentiatorsSection,
  FeaturesSection,
} from 'components';

export const slices = {
  PrismicSectionBodyFeatures: {
    component: FeaturesSection,
    documentName: 'feature',
  },
  PrismicSectionBodyDifferentiators: {
    component: DifferentiatorsSection,
    documentName: 'differentiator',
  },
  PrismicSectionBodyAwards: {
    component: AwardsSection,
    documentName: 'award',
  },
};

export default item => {
  let section = {};
  let data = item.document[0].data;
  section.title = data.title.text;
  section.description = data.description.text;
  section.button = {
    text: data.button_text,
    href: data.button_link,
  };
  if (data.body) {
    let dataBody = data.body[0];
    section.type = dataBody.__typename;
    let { documentName } = slices[section.type];
    section[`${documentName}s`] = dataBody.items.map(item => {
      const { [documentName]: document, ...rest } = item;
      return Object.assign(document.document[0].data, rest);
    });
  }
  return section;
};
