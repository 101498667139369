import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { About, CTA, Hero, Head } from 'components';
import parseSection, { slices } from 'parsers/sections';

/* #region Index Query */
export const query = graphql`
  query IndexQuery {
    page: prismicIndex {
      data {
        meta_title
        hero {
          document {
            ...Section
          }
        }
        about {
          text
        }
        sections {
          section {
            document {
              ...Section
            }
          }
        }
        cta {
          document {
            ...Section
          }
        }
      }
    }
  }
`;
/* #endregion */

const Wrapper = styled.div`
  display: block;
  flex-direction: column;
  width: 100%;
`;

const IndexPage = ({ data }) => {
  let { meta_title, hero, about, sections, cta } = data.page.data;
  hero = parseSection(hero);
  about = about.text;
  sections = sections.map(({ section }) => parseSection(section));
  cta = parseSection(cta);
  return (
    <Fragment>
      <Head />
      <Wrapper>
        <Hero {...hero} />
        <About about={about} />
        {sections.map((section, i) => {
          const Section = slices[section.type].component;
          return <Section key={i} {...section} dark={i % 2 === 1} />;
        })}
        <CTA {...cta} />
      </Wrapper>
    </Fragment>
  );
};


export default IndexPage;
